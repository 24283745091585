<template>
	<div>
    <v-app-bar
			app
			fixed
			outlined
			dense
			color="#ffffff"
			elevation="0.5"
		>
      <v-icon @click="$router.push({ name: 'home' }).catch()">mdi-close</v-icon>
      <v-toolbar-title class="text-caption font-weight-6 ml-3">
        <div class="font-weight-bold">
          Menu <v-icon small>mdi-clipboard-list-outline</v-icon>
        </div>
        <small class="position-relative" style="top: -3px">{{
          profile.restaurant_name
        }}</small>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="text-caption font-weight-bold"
				@click="goToOrder()"
      >Order Now</v-btn>
    </v-app-bar>
		<v-card
			:class="{ 'has-mouse': hasMouse }"
			class="flipbook-card bg-light"
			@touchstart="hasMouse = false"
		>
			<flipbook
				class="flipbook"
				v-slot="flipbook"
				ref="flipbook"
				:pages="menus"
				:startPage="pageNum"
				:singlePage="true"
				@flip-left-end="onFlipLeftEnd"
				@flip-right-end="onFlipRightEnd"
			>
				<div class="action-bar">
					<v-btn
						icon
						large
						class="primary white--text"
						:class="{ disabled: !flipbook.canFlipLeft }"
						@click.stop="flipbook.flipLeft"
					>
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-btn
						icon
						large
						class="primary white--text ml-3"
						:class="{ disabled: !flipbook.canZoomIn }"
						@click.stop="flipbook.zoomIn"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
					<span class="text-body-1 font-weight-6 blue-grey--text text--darken-1 mx-3">
						Page {{ flipbook.page }} of {{ flipbook.numPages }}
					</span>
					<v-btn
						icon
						large
						class="primary white--text mr-3"
						:class="{ disabled: !flipbook.canZoomOut }"
						@click.stop="flipbook.zoomOut"
					>
						<v-icon>mdi-minus</v-icon>
					</v-btn>
					<v-btn
						icon
						large
						class="primary white--text"
						:class="{ disabled: !flipbook.canFlipRight }"
						@click.stop="flipbook.flipRight"
					>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</div>
			</flipbook>
		</v-card>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Flipbook from 'flipbook-vue';

export default {
  components: {
    Flipbook,
  },
	data() {
		return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
			flipbook: null,
			menus: [],
			hasMouse: true,
			pageNum: 1,
		}
	},
	computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
		}),
    ...mapGetters('merchant', {
      hasDelivery: 'hasDelivery',
      hasPickup: 'hasPickup',
    }),
	},
	methods: {
		...mapMutations('cart', ['SET_SERVICE']),
    onFlipLeftEnd(page) {
      window.location.hash = '#' + page;
		},
    onFlipRightEnd(page) {
      window.location.hash = '#' + page;
		},
		setPageFromHash() {
      const n = parseInt(window.location.hash.slice(1), 10);
      this.pageNum = n || 1;
		},
		goToOrder() {
			if (this.hasDelivery) {
				this.SET_SERVICE('delivery');
				this.$router.push({ name: 'menu' }).catch();
			} else if (this.hasPickup) {
				this.SET_SERVICE('pickup');
				this.$router.push({ name: 'menu' }).catch();
			} else {
				this.SET_SERVICE('dinein');
				this.$router.push({ name: 'scan' }).catch();
			}
		},
	},
	mounted() {
		if (!this.profile?.options.merchant_photo_menubook) {
			this.$router.push({ name: 'menu' });
		}

		this.menus = this.profile?.options.merchant_photo_menubook.split('|')?.map(url => `${this.cdnUrl}/${url}`);

    window.addEventListener('keydown', (ev) => {
      this.flipbook = this.$refs.flipbook;
			if (!this.flipbook) return;
			if (ev.code == 'ArrowLeft' && this.flipbook.canFlipLeft) this.flipbook.flipLeft();
      if (ev.code == 'ArrowRight' && this.flipbook.canFlipRight) this.flipbook.flipRight();
		});

		window.addEventListener('hashchange', () => {
			this.setPageFromHash();
		});

		this.setPageFromHash();
	}
};
</script>
<style>
.flipbook-card {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
	overflow: hidden;
}
.v-toolbar {
  box-shadow: 0px 2px 4px -1px rgb(255 99 71 / 20%),
    0px 4px 5px 0px rgb(255 99 71 / 14%), 0px 1px 10px 0px rgb(255 99 71 / 12%) !important;
}
.flipbook, .flipbook .viewport {
  width: 90vw !important;
  height: calc(100vh - 40px - 50px) !important;
}
.flipbook .bounding-box {
	border-radius: 5px;
  box-shadow: 0 0 20px #000;
}
.flipbook .page {
	border-radius: 5px;
}
.action-bar {
  width: 90%;
	position: fixed;
	z-index: 9;
	bottom: 15px;
	text-align: center;
}
.has-mouse .action-bar .v-btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .v-btn:active {
  filter: none !important;
}
.action-bar .v-btn.disabled {
  pointer-events: none;
}
@media screen and (min-width: 1024px) {
	.flipbook, .flipbook .viewport {
		width: 373px !important;
	}
	img.page.fixed {
		width: 373px !important;
	}
	.action-bar {
		width: 373px !important;
	}
}
</style>